import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryDefaultSkin/MatrixGalleryDefaultSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryDefaultSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryDefaultSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryTextSlideUpSkin/MatrixGalleryTextSlideUpSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextSlideUpSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryTextSlideUpSkin',
    );

    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryCircleSkin/MatrixGalleryCircleSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryCircleSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryCircleSkin',
    );

    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryLiftedShadow/MatrixGalleryLiftedShadow.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryLiftedShadow" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryLiftedShadow',
    );

    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryScotchTapeSkin/MatrixGalleryScotchTapeSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryScotchTapeSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryScotchTapeSkin',
    );
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryIronSkin/MatrixGalleryIronSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryIronSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryIronSkin',
    );
  },
};

export default entry;
